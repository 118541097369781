import {Routes, Route} from "react-router-dom";

import HomePage from "./pages/home/HomePage";
import Menu from "./core/Menu";
import Footer from "./core/Footer";

export default function MainRouter() {
    return (
        <>
            <Menu/>
            <Routes>
                <Route exact path="/" element={<HomePage />}/>
            </Routes>
            <Footer/>
        </>
    );
}