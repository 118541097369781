import Carousel from 'react-bootstrap/Carousel';

import About from "../../resources/images/about.jpg";
import Image1 from "../../resources/images/slides/15.jpg";
import Image2 from "../../resources/images/slides/2.jpeg";
import Image3 from "../../resources/images/slides/3.jpg";
import Image4 from "../../resources/images/slides/4.jpg";
import Image5 from "../../resources/images/slides/5.jpg";

export default function AboutCompanyAndSl() {
    return (
        <section id="about-us">
            <div id="about" className="about">
                <div className="container" data-aos="fade-up">

                    <div className="row align-items-center">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            <img src={About} className="img-fluid" alt="Golden Bridge Tours (Pvt) Ltd"/>
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                            <h3>Golden Bridge Tours (Pvt) Ltd</h3>
                            <h4>Your Gateway to Discovering the Wonders of Sri Lanka</h4>
                            <p className="fst-italic">
                                Welcome to Golden Bridge Tours (Pvt) Ltd., your premier destination management
                                company in Sri Lanka. Our mission is to provide you with an unforgettable travel
                                experience in the beautiful island of Sri Lanka.
                            </p>
                            <p>
                                At Golden Bridge Tours, we specialize in providing customized travel packages for
                                Chinese tourists, ensuring that you have an authentic and immersive experience of Sri
                                Lankas culture, history, and natural beauty. Our team of experienced travel experts will
                                work with you to create a travel itinerary that suits your preferences, interests, and
                                budget, so you can enjoy a hassle-free and memorable journey in Sri Lanka.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div id="about-sl" className="about-sl">
                <div className="container" data-aos="fade-up">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-1" data-aos="fade-left" data-aos-delay="100">
                            <Carousel>
                                <Carousel.Item interval={4000}>
                                    <img className="d-block w-100" src={Image1} alt="Golden Bridge Tours (Pvt) Ltd"/>
                                </Carousel.Item>
                                <Carousel.Item interval={4000}>
                                    <img className="d-block w-100" src={Image2} alt="Golden Bridge Tours (Pvt) Ltd"/>
                                </Carousel.Item>
                                <Carousel.Item interval={4000}>
                                    <img className="d-block w-100" src={Image3} alt="Golden Bridge Tours (Pvt) Ltd"/>
                                </Carousel.Item>
                                <Carousel.Item interval={4000}>
                                    <img className="d-block w-100" src={Image4} alt="Golden Bridge Tours (Pvt) Ltd"/>
                                </Carousel.Item>
                                <Carousel.Item interval={4000}>
                                    <img className="d-block w-100" src={Image5} alt="Golden Bridge Tours (Pvt) Ltd"/>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 content" data-aos="fade-left" data-aos-delay="100">
                            <h3>Welcome to Sri Lanka</h3>
                            <h4>"Pearl of the Indian Ocean"</h4>
                            <p className="fst-italic">
                                Sri Lanka, also known as the "Pearl of the Indian Ocean", is a tropical paradise located
                                off the southern coast of India. With its lush green landscapes, pristine beaches, rich
                                culture, and friendly people, Sri Lanka is a destination that offers something for
                                everyone.
                            </p>
                            <p>
                                One of the main attractions of Sri Lanka is its rich cultural heritage. The island is home
                                to eight UNESCO World Heritage sites, including ancient cities, temples, and fortresses
                                that showcase the island's rich history. The most famous of these sites is the ancient
                                city of Anuradhapura, which was the political and religious capital of Sri Lanka for over
                                1,300 years.
                            </p>

                            <p>
                                Another major draw of Sri Lanka is its diverse wildlife. The island is home to a wide
                                range of animal species, including elephants, leopards, and several species of primates.
                                Visitors can also go on safari in one of Sri Lanka's many national parks, such as Yala
                                National Park, which is home to the largest population of leopards in the world.
                            </p>

                            <p>
                                Sri Lanka is also renowned for its beautiful beaches, which offer visitors a chance to
                                relax and unwind in paradise. Some of the most popular beaches include Unawatuna,
                                Mirissa, and Hikkaduwa, which are known for their golden sands and clear blue waters.
                            </p>

                            <p>
                                In addition to its natural beauty, Sri Lanka is also famous for its cuisine. The island's
                                unique blend of spices and flavors has created a cuisine that is both delicious and
                                distinctive. Some of the must-try dishes include rice and curry, string hoppers, and
                                hoppers.
                            </p>

                            <p>
                                Overall, Sri Lanka is a destination that offers something for everyone. Whether you're
                                interested in history, wildlife, or simply relaxing on the beach, this tropical paradise is the
                                perfect place to visit.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
