
function Slider() {
    return (
        <section id="home" className="d-flex align-items-center justify-content-center">
            <div className="container" data-aos="fade-up">

                <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="150">
                    <div className="col-xl-6 col-lg-8">
                        <h1>Welcome to the Golden Bridge Tours <span>.</span></h1>
                        <h2>We are the bridge to make your travel dreams a reality</h2>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Slider;