import Accordion from 'react-bootstrap/Accordion';
import {CheckLg} from 'react-bootstrap-icons';

export default function Tour() {
    return (
        <section id="tours" className="tours">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Itinerary</h2>
                    <p>Sri Lanka 07 Days/06 Nights Tour Package</p>
                </div>
                <p>
                    This itinerary includes visits to some of Sri Lanka&#39;s most popular tourist
                    destinations, such as <strong>Sigiriya</strong>, <strong>Kandy</strong>, and <strong>Yala National
                    Park</strong>, as well as some
                    more off-the-beaten-path spots like <strong>Nuwara Eliya</strong> and <strong>Mirissa</strong>. Of
                    course,
                    there are many other places to explore in Sri Lanka depending on your
                    interests and preferences, so feel free to customize this itinerary to suit your
                    needs.
                </p>
                <Accordion defaultActiveKey={['0']}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><strong>Day 1: Colombo - Sigiriya</strong></Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li><i><CheckLg size={20} /></i>Arrive at Colombo International Airport</li>
                                <li><i><CheckLg size={20} /></i>Drive to Sigiriya (approximately 4 hours)</li>
                                <li><i><CheckLg size={20} /></i>Visit the ancient city of Polonnaruwa (UNESCO
                                    World Heritage Site)
                                </li>
                                <li><i><CheckLg size={20} /></i>Climb Sigiriya Rock Fortress (UNESCO World
                                    Heritage Site)
                                </li>
                            </ul>
                            <span className="badge rounded-pill">Overnight in Sigiriya</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><strong>Day 2: Sigiriya - Kandy</strong></Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li><i><CheckLg size={20} /></i>Visit the Dambulla Cave Temple (UNESCO World
                                    Heritage Site)
                                </li>
                                <li><i><CheckLg size={20} /></i>Drive to Kandy (approximately 3 hours)</li>
                                <li><i><CheckLg size={20} /></i>Visit the Temple of the Tooth Relic</li>
                                <li><i><CheckLg size={20} /></i>Watch a cultural dance performance in the
                                    evening
                                </li>
                            </ul>
                            <span className="badge rounded-pill">Overnight in Kandy</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><strong>Day 3: Kandy - Nuwara Eliya</strong></Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li><i><CheckLg size={20} /></i>Drive to Nuwara Eliya (approximately 3 hours)
                                </li>
                                <li><i><CheckLg size={20} /></i>Visit a tea plantation and factory en route
                                </li>
                                <li><i><CheckLg size={20} /></i>Explore the town of Nuwara Eliya, also known
                                    as &quot;Little England&quot;</li>
                            </ul>
                            <span className="badge rounded-pill">Overnight in Nuwara Eliya</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><strong>Day 4: Nuwara Eliya - Yala National Park</strong></Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li><i><CheckLg size={20} /></i>Drive to Yala National Park (approximately 4
                                    hours)
                                </li>
                                <li><i><CheckLg size={20} /></i>Go on an afternoon jeep safari in the park
                                </li>
                            </ul>
                            <span className="badge rounded-pill">Overnight near Yala National Park</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><strong>Day 5: Yala National Park - Mirissa</strong></Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li><i><CheckLg size={20} /></i>Go on an early morning jeep safari in the park
                                </li>
                                <li><i><CheckLg size={20} /></i>Drive to Mirissa (approximately 3 hours)</li>
                                <li><i><CheckLg size={20} /></i>Relax on the beach or go whale watching
                                    (depending on the season)
                                </li>
                            </ul>
                            <span className="badge rounded-pill">Overnight in Mirissa</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header><strong>Day 6: Mirissa - Colombo</strong></Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li><i><CheckLg size={20} /></i>Drive to Colombo (approximately 3 hours)</li>
                                <li><i><CheckLg size={20} /></i>Go on a city tour of Colombo, including visits
                                    to the National Museum, Gangaramaya Temple, and Independence Square
                                </li>
                                <li><i><CheckLg size={20} /></i>Shop for souvenirs at the local markets</li>
                            </ul>
                            <span className="badge rounded-pill">Overnight in Colombo</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header><strong>Day 7: Departure</strong></Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li><i><CheckLg size={20} /></i>Transfer to the airport for your departure
                                    flight
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </section>
    );
}