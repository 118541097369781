import React, {useEffect, useState} from "react";
import {BrowserRouter} from "react-router-dom";
import MainRouter from "./MainRouter";

// Importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';

// Bootstrap CSS JS
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

// Importing styles
import "./resources/styles/app.scss";
import "./resources/js/main.js"

export default function App() {

    const [dvcSize, setDvcSize] = useState({
        tab: false,
        mob: false,
        web: false,
    });

    const {tab, mob, web} = dvcSize;
    if(web){
        document.body.classList.remove('mob','tab')
        document.body.classList.add('web')
    } else if(mob){
        document.body.classList.remove('web','tab')
        document.body.classList.add('mob')
    }else if(tab){
        document.body.classList.remove('mob','web')
        document.body.classList.add('tab')
    }

    function deviceSize() {
        if (window.innerWidth <= 991 && window.innerWidth >= 768) {
            setDvcSize({
                ...dvcSize,
                tab: true
            });
        } else if (window.innerWidth <= 767) {
            setDvcSize({
                ...dvcSize,
                mob: true
            });
        } else {
            setDvcSize({
                ...dvcSize,
                web: true
            });
        }
    }

    useEffect(() => {
        AOS.init();
        deviceSize();
        window.addEventListener('resize', deviceSize);
    }, [])

    return (
        <BrowserRouter>
            <MainRouter/>
        </BrowserRouter>
    );

}
