import {GeoAlt, Envelope, Phone} from 'react-bootstrap-icons';

export default function ContactForm() {
    return (
        <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Contact</h2>
                    <p>Contact Us</p>
                </div>
                <div>
                    <iframe title="contact-us-map" style={{border:0, width: `100%`, height: `280px`}} src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d247.5517383096266!2d79.85189673396867!3d6.911181509935587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNsKwNTQnNDAuMiJOIDc5wrA1MScwNi40IkU!5e0!3m2!1sen!2slk!4v1685293867060!5m2!1sen!2slk" frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <div className="info">
                            <div className="row">
                                <div className="col-12 col-md-4 address">
                                    <i><GeoAlt size={24}/></i>
                                    <h4>Head Office:</h4>
                                    <p>Golden Bridge Tours (Pvt) Ltd.</p>
                                    <p>No. 243 / 1, R.A.De Mel Mawatha, Colombo 03.</p>
                                </div>
                                <div className="col-12 col-md-4 email">
                                    <i><Envelope size={24}/></i>
                                    <h4>Email:</h4>
                                    <p>goldenbridge.tours@gmail.com</p>
                                </div>
                                <div className="col-12 col-md-4 phone">
                                    <i><Phone size={24}/></i>
                                    <h4>Call:</h4>
                                    <p>0112 285888</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 mt-5 mt-lg-0 d-none">

                        <form action="" method="post" className="php-email-form">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                                </div>
                                <div className="col-md-6 form-group mt-3 mt-md-0">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
                            </div>
                            <div className="form-group mt-3">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message" required/>
                            </div>
                            <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"/>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div className="text-center">
                                <button type="submit">Send Message</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}