import {Link} from "react-router-dom";
import {Facebook, Instagram} from 'react-bootstrap-icons';
import Logo from "../resources/images/logo-tr.png";

export default function Footer() {

    const currentYear = new Date().getFullYear();

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    const scrollTo = (el) => {
        let header = select('#header')
        let offset = header.offsetHeight

        let elementPos = select(el).offsetTop
        window.scrollTo({
            top: elementPos - offset,
            behavior: 'smooth'
        })
    }

    const navigateTo = () => {

        let selectEl = select(".scrollto", true)
        if (selectEl) {
            selectEl.forEach(e => e.addEventListener("click", function(e) {
                if (select(this.hash)) {
                    e.preventDefault()
                    let navbar = select('#navbar')
                    if (navbar.classList.contains('navbar-mobile')) {
                        navbar.classList.remove('navbar-mobile')
                        let navbarToggle = select('.mobile-nav-toggle')
                        navbarToggle.classList.toggle('bi-list')
                        navbarToggle.classList.toggle('bi-x')
                    }
                    scrollTo(this.hash)
                }
            }))
        }
    }

    return (
        <footer className="footer-section">
            <div className="container">
                <div className="footer-content pt-5 pb-5">
                    <div className="row justify-content-between">
                        <div className="col-lg-4 col-md-6 mb-5">
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={navigateTo} to="#home" className="scrollto" >Home</Link></li>
                                    <li><Link onClick={navigateTo} to="#services" className="scrollto" >Our Services</Link></li>
                                    <li><Link onClick={navigateTo} to="#about-us" className="scrollto" >About</Link></li>
                                    <li><Link onClick={navigateTo} to="#contact" className="scrollto" >Contact</Link></li>
                                    <li><Link onClick={navigateTo} to="#tours" className="scrollto" >Tour</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 mb-5">
                            <div className="footer-social-icon">
                                <div className="footer-widget-heading">
                                    <h3>Follow us</h3>
                                </div>
                                <Link to="https://www.facebook.com/profile.php?id=100093576452113&mibextid=LQQJ4d" target="_blank"><Facebook size={30}/></Link>
                                <Link to="#"><Instagram size={30}/></Link>
                            </div>
                        </div>
                        <div className="col-lg mb-5">
                            <div className="footer-text">
                                <p>Your Bridge to remarkable experiences in an unforgettable Destination</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-widget">
                                <div className="footer-logo text-center">
                                    <Link to="/">
                                        <img src={Logo} className="img-fluid" alt="Golden Bridge Tours (Pvt) Ltd"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="copyright-text">
                                <p>Copyright &copy; {currentYear}, All Right Reserved</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 d-none text-end">
                            <div className="footer-menu">
                                <ul>
                                    <li><Link onClick={navigateTo} to="#home">Home</Link></li>
                                    <li><Link onClick={navigateTo} to="#about-us">About</Link></li>
                                    <li><Link onClick={navigateTo} to="#tours">Tour</Link></li>
                                    <li><Link onClick={navigateTo} to="#services">Our Services</Link></li>
                                    <li><Link onClick={navigateTo} to="#contact">Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}