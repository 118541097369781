import {Link} from "react-router-dom";
import {Calendar2Week, Buildings, BusFront, InfoCircle, Bicycle} from 'react-bootstrap-icons';

function Services() {
    return (
        <section id="services" className="services">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Our Services</h2>
                    <p>We offer a wide range of services to cater to your travel needs, including:</p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in"
                         data-aos-delay="100">
                        <div className="icon-box">
                            <div className="icon"><Calendar2Week size={36} /></div>
                            <h4><Link to="">Tailored travel packages</Link></h4>
                            <p>We offer customized travel packages that are designed
                                to meet your unique requirements and preferences, so you can explore the best
                                of Sri Lanka at your own pace.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in"
                         data-aos-delay="200">
                        <div className="icon-box">
                            <div className="icon"><Buildings size={36} /></div>
                            <h4><Link to="">Accommodation</Link></h4>
                            <p>We provide a range of accommodation options to suit every
                                budget, from luxurious five-star hotels to cozy guesthouses and homestays.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in"
                         data-aos-delay="300">
                        <div className="icon-box">
                            <div className="icon"><BusFront size={36} /></div>
                            <h4><Link to="">Transportation</Link></h4>
                            <p>We offer comfortable and safe transportation options, including
                                private cars, buses, and trains, to help you get around Sri Lanka easily and
                                conveniently.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in"
                         data-aos-delay="100">
                        <div className="icon-box">
                            <div className="icon"><InfoCircle size={36} /></div>
                            <h4><Link to="">Tour guides</Link></h4>
                            <p>Our professional and knowledgeable tour guides will accompany
                                you throughout your journey, providing insights into Sri Lanka&#39;s history, culture,
                                and traditions.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in"
                         data-aos-delay="200">
                        <div className="icon-box">
                            <div className="icon"><Bicycle size={36}/></div>
                            <h4><Link to="">Activities</Link></h4>
                            <p>We offer a range of activities and experiences to enhance your travel
                                experience, including wildlife safaris, cultural tours, and adventure activities such
                                as hiking, cycling, and water sports.</p>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
}

export default Services;