import React,{useState, useEffect} from "react";
import {ArrowUp, List, X} from 'react-bootstrap-icons';

//import {Link, useMatch, useResolvedPath} from "react-router-dom";
import {Link} from "react-router-dom";
import Logo from "../resources/images/logo-tr.png";


export default function Menu() {

    let MenuIcon;
    const scrollStart = 100;
    const [scroll, setScroll] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const navbarlinksActive = () => {
        let navbarlinks = select('#navbar .scrollto', true)
        let position = window.scrollY + 200
        navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return
            let section = select(navbarlink.hash)
            if (!section) return
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarlink.classList.add('active')
            } else {
                navbarlink.classList.remove('active')
            }
        })
    }

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    const onScroll = () => {
        const top = window.pageYOffset;
        if(top > scrollStart){
            setScroll(true);
        }else {
            setScroll(false);
        }
    }

    const scrollTo = (el) => {
        let header = select('#header')
        let offset = header.offsetHeight

        let elementPos = select(el).offsetTop
        window.scrollTo({
            top: elementPos - offset,
            behavior: 'smooth'
        })
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const navigateTo = () => {

        setMenuOpen( false);

        let selectEl = select(".scrollto", true)
        if (selectEl) {
            selectEl.forEach(e => e.addEventListener("click", function(e) {
                if (select(this.hash)) {
                    e.preventDefault()
                    let navbar = select('#navbar')
                    if (navbar.classList.contains('navbar-mobile')) {
                        navbar.classList.remove('navbar-mobile')
                        let navbarToggle = select('.mobile-nav-toggle')
                        navbarToggle.classList.toggle('bi-list')
                        navbarToggle.classList.toggle('bi-x')
                    }
                    scrollTo(this.hash)
                }
            }))
        }
    }

    const toggleMobileMenu = () => {
        setMenuOpen(!menuOpen);
    }

    if (menuOpen) {
        MenuIcon = <X size={50} />;
    } else {
        MenuIcon = <List size={40} />;
    }

    // const CustomLink = ({ to, children, ...props }) => {
    //     const resolvePath = useResolvedPath(to)
    //     const isActive = useMatch({path: resolvePath.pathname, end: true})
    //     return (
    //         <Link to={to} className={isActive ? "active" : "inactive"} {...props}>{children}</Link>
    //     )
    // }

    useEffect(() => {
        window.addEventListener("load", navbarlinksActive);
        window.addEventListener("load", navigateTo);
        window.addEventListener("scroll", onScroll);
        window.addEventListener("scroll", navbarlinksActive);
    },[onScroll,navbarlinksActive]);

    return (
        <>
            <header id="header" className={`fixed-top${scroll?" header-scrolled":""}`}>
                <div className="container d-flex align-items-center justify-content-lg-between">
                    <h1 className="logo me-auto me-lg-0">
                        <Link to="/" className="logo me-auto me-lg-0">
                            <img src={Logo} alt="Golden Bridge Tours (Pvt) Ltd" className="img-fluid"/>
                        </Link>
                    </h1>

                    <nav id="navbar" className={`navbar order-last order-lg-0${menuOpen?" navbar-mobile":""}`}>
                        <ul>
                            <li><Link className="nav-link scrollto active" to="#home" >Home</Link></li>
                            <li><Link className="nav-link scrollto" to="#about-us" >About</Link></li>
                            <li><Link className="nav-link scrollto" to="#tours" >Tour</Link></li>
                            <li><Link className="nav-link scrollto" to="#services" >Our Services</Link></li>
                            <li><Link className="nav-link scrollto" to="#contact" >Contact</Link></li>
                        </ul>
                        <div className="mobile-nav-toggle" onClick={toggleMobileMenu}>
                            {MenuIcon}
                        </div>
                    </nav>
                </div>
                <Link to="#" className={`back-to-top d-flex align-items-center justify-content-center${scroll? " active":""}`} onClick={scrollToTop}>
                    <i><ArrowUp size={20}/></i>
                </Link>
            </header>
        </>
    );
}