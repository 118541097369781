import AboutCompanyAndSl from "../about-company-and-sl/AboutCompanyAndSl";
import Services from "../services/Services";
import Questions from "../question/Questions";
import ContactForm from "../contact-form/ContactForm";
import Slider from "../../core/Slider";
import React from "react";
import Tour from "../tour/Tour";

export default function HomePage() {
    return (
        <main id="Main">
            <Slider/>
            <AboutCompanyAndSl/>
            <Tour/>
            <Questions/>
            <Services/>
            <ContactForm/>
        </main>
    );
}