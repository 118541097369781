import {Link} from "react-router-dom";

function Questions() {

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    const navigateTo = () => {
        let header = select('#header')
        let offset = header.offsetHeight
        let element = select('.cta-btn')
        let elementPos = select(element.hash).offsetTop
        window.scrollTo({
            top: elementPos - offset,
            behavior: 'smooth'
        })
    }

    return (
        <section id="cta" className="cta">
            <div className="container" data-aos="zoom-in">
                <div className="text-center">
                    <h3>Have a question or need a custom quote?</h3>
                    <p>We would love to hear from you and help you plan your next trip to Sri Lanka. Contact
                        us today to learn more about our services and to start planning your dream vacation.</p>
                    <Link className="cta-btn" onClick={navigateTo} to="#contact">Contact Us Today</Link>
                </div>

            </div>
        </section>
    );
}

export default Questions;